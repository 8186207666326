import React from "react";
import { Security } from "@okta/okta-react";
import { UserProvider, oktaAuth } from "../contexts/UserContext";
import HttpInterceptor from "../api/HttpInterceptor";
import { createTheme, ThemeProvider } from "@mui/material";

const WrapperProvider: React.FC = ({ children }) => {
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: string): Promise<void> => {
    window.location.replace(originalUri);
  };

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1025,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <UserProvider>
        <HttpInterceptor>
          <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </HttpInterceptor>
      </UserProvider>
    </Security>
  );
};

export default WrapperProvider;
