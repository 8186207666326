import App from "./App";
import React from "react";
import ReactDom from "react-dom";
import WrapperProvider from "./components/WrapperProvider";

const GlobalUmpireStyles = `
  --color-app-background: #1e2f3f;
  --color-off-white: #f5f7fa;
  --color-nav-gray: #a1acb5;
  --color-navy-blue: #1b4261;
  --color-mlb-red: #fe0000;
  --color-top-nav-background: #3c3c3c;
  --color-light-gray: #f5f5f5;
  --color-light-blue: #1fbeec;
`;

ReactDom.render(
  <WrapperProvider>
    <style>{`
            html {
              ${GlobalUmpireStyles}
            }
            body {
                margin: 0px;
                padding: 0px !important;
            }
        `}</style>
    <App />
  </WrapperProvider>,
  document.getElementById("umpire-ui"),
);
