import { CircularProgress } from "@mui/material";
import React, { lazy, Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { useUser } from "./contexts/UserContext";

const AuthenticatedApp = lazy(() => import("./components/AuthenticatedApp"));
const UnauthenticatedApp = lazy(() => import("./components/UnauthenticatedApp"));

const App: React.FC = () => {
  const { loggedIn } = useUser();

  return (
    <>
      <Suspense
        fallback={
          <div style={{ textAlign: "center", margin: "auto" }}>
            <CircularProgress />
          </div>
        }
      >
        <BrowserRouter>{loggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />}</BrowserRouter>
      </Suspense>
    </>
  );
};

export default App;
