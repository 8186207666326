import React, { createContext, useContext, useState, Dispatch, SetStateAction, useEffect } from "react";
import config from "../config";
import { OktaAuth } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { getUserInfo } from "../api/UserApi";

type UserInfo = {
  accessToken: { accessToken: string };
  userName?: string;
  userFullName?: string;
  email?: string;
  userId?: number;
};

type UserContext = {
  userInfo: UserInfo;
  loggedIn: boolean;
  login: (data: UserInfo) => void;
  logout: () => void;
  permissionError: boolean;
  loginFunction: SetStateAction<void>;
  setPermissionError: Dispatch<SetStateAction<boolean>>;
  setLoginFunction: Dispatch<SetStateAction<void>>;
  getUserId(): number;
};

const UserContext = createContext<UserContext>({
  userInfo: null,
  loggedIn: false,
  login: null,
  logout: null,
  permissionError: false,
  loginFunction: null,
  setPermissionError: null,
  setLoginFunction: null,
  getUserId: null,
});

const initialState = {
  userInfo: {
    accessToken: { accessToken: "" },
    userName: "",
    userFullName: "",
    email: "",
    userId: 0,
  },
  loggedIn: false,
};

const oktaAuth = new OktaAuth(config.oidc);

const UserProvider: React.FC = ({ children }) => {
  const [userInfo, setUserInfo] = useState<UserInfo>(initialState.userInfo);
  const [loggedIn, setLoggedIn] = useState<boolean>(initialState.loggedIn);
  const [permissionError, setPermissionError] = useState<boolean>(false);
  const [loginFunction, setLoginFunction] = useState(null);
  const { authState } = useOktaAuth();

  const getUserId = (): number => {
    return userInfo.userId;
  };

  const login = async (userData: UserInfo): Promise<void> => {
    const userInfoData = await getUserInfo();
    if (userInfoData) {
      userData = { ...userData, ...userInfoData };
      setUserInfo(userData);
      setLoggedIn(true);
    }
  };

  const logout = (): void => {
    localStorage.clear();
    setUserInfo(initialState.userInfo);
    setLoggedIn(false);
  };

  useEffect(() => {
    if (authState?.isAuthenticated && !loggedIn) {
      // when the user becomes authenticated, call onLogin() to populate AuthContext's user info
      login({ accessToken: authState.accessToken });
    }
  }, [authState]);

  return (
    <UserContext.Provider
      value={{
        userInfo,
        loggedIn,
        login,
        logout,
        permissionError,
        setPermissionError,
        loginFunction,
        setLoginFunction,
        getUserId,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUser = (): UserContext => {
  const userContext = useContext<UserContext>(UserContext);
  if (userContext === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }
  return userContext;
};

export { UserContext, UserProvider, oktaAuth, useUser, UserInfo };
